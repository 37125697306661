import 'core-js/fn/object/assign.js';
import 'core-js/fn/array/find.js';
import 'core-js/fn/array/find-index.js';
import 'core-js/fn/array/fill.js';
import 'core-js/fn/array/includes.js';
import 'core-js/fn/array/from.js';
import './closest.js';
import svg4everybody from './svg4everybody.js';
import objectFitImages from 'object-fit-images';

// for react 16
// https://facebook.github.io/react/blog/2017/09/26/react-v16.0.html#javascript-environment-requirements
import 'core-js/es6/map.js';
import 'core-js/es6/set.js';

// window.fetch and window.Promise
import 'whatwg-fetch';
import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

svg4everybody();
objectFitImages('.js-object-fit');
